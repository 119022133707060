@import '../imports.scss';

.hashtagSection{
  padding: 80px 0;
  text-align: center;
  background: white;

  h2{
    font-family: "Open Sans", sans-serif;
    text-transform: none;
  }

  p{
    line-height: 1.4em;
    margin-bottom: 30px;
    padding: 0 15px;
  }
}

.hashtagPhotos{
  max-width: 1100px;
  margin: auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.hashtagPhoto{
  position: relative;
  margin: 20px;
  max-width: 30%;
  width: 300px;
  height: 300px;

  img{
    max-width: 100%;
    width: 300px;
    height: 100%;
    object-fit: cover;
  }

  .hashtagPhotoOverlay{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: all .1s linear;
    background-color: rgba(0,0,0,0.4);
  }

  &:hover .hashtagPhotoOverlay{
    opacity: 1;
  }

  .hashtagPhotoText{
    color: white;
    font-size: 16px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
}

