@import "../imports";

.formItem{
  margin: 15px 0;
}

.sectionTitle{
  text-align: center;
  margin-bottom: 30px;
  font-size: 21px;
  font-weight: 500;
}

.orderList{
  margin: 20px 0;
}

.orderItem{
  position: relative;
  padding: 20px 30px;
  //cursor: pointer;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  max-width: 100%;
  width: 100%;
  margin-bottom: 20px;

  i{
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 30px;
    color: $color-primary;
  }

  &.active{
    background: #FAFAFA;
  }

  &:hover{
    background: #FAFAFA;
  }
}

.orderItemLabel{
  font-size: 16px;
  font-weight: 600;
}

.orderItemName{
  margin: 5px 0;
}

.orderItemNotes{
  margin-top: 5px;
}



.shippingMethodList{
  margin: 30px 0;
  text-align: center;
}

.shippingMethodItem{
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 20px 30px;
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  max-width: 100%;
  width: 500px;
  margin-bottom: 20px;

  i{
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 30px;
    color: $color-primary;
  }

  &.active{
    background: #FAFAFA;
  }

  &:hover{
    background: #FAFAFA;
  }
}

.shippingMethodName{
  font-size: 16px;
  font-weight: 500;
}

.shippingMethodService{
  margin: 5px 0;
}

.shippingMethodPrice{
  font-size: 18px;
  font-weight: 500;
}

.shippingMethodNotes{
  margin-top: 5px;
}


.showroomList{
  display: flex;
  flex-flow: row wrap;
  justify-content: left;
  margin-bottom: 50px;
}

.showroomListActions{
  text-align: center;

  button{
    margin: 10px;
  }
}

.showroomRegionName{
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 15px;
}

.showroomItem{
  position: relative;
  width: 350px;
  max-width: 100%;
  padding: 20px 25px 70px 25px;
  margin-bottom: 20px;
  margin-right: 20px;
  border-radius: 2px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);

  &:hover{
    box-shadow: 0 7px 14px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22);
  }
}

.showroomName{
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 10px;
  margin-top: 5px;
}

.showroomAddress{
  margin-bottom: 0;
  line-height: 1.7em;
}

.showroomActions{
  position: absolute;
  right: 25px;
  bottom: 20px;
  text-align: right;

  a{
    display: inline-block;
    margin-left: 20px;
  }
}

.showroomSelectButton{
  //position: absolute;
  //bottom: 10px;
  //right: 10px;
  position: absolute;
  left: 25px;
  bottom: 20px;
  padding: 5px 20px;
  margin-top: 15px;
  outline: none;
  border: 0;
  background: $color-primary;
  color: white;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

.cartTable{
  th{
    font-family: "Open Sans", sans-serif;
  }
}

.paymentMethodArea{
  text-align: right;
  margin: 80px 30px 30px 30px;

  h4{
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
  }
}

.paymentMethodItem{
  width: 200px;
  max-width: 100%;
  margin-left: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  padding: 20px;
  cursor: pointer;
  text-align: center;
  border-top: 1px solid rgba(50,50,50,0.1);

  &:hover{
    box-shadow: 0 7px 14px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22);
  }

  img{
    width: 150px;
    max-width: 80%;
  }
}

.trackingHistory{
  list-style: circle;

  li{
    margin-bottom: 10px;

    &:first-child{
      font-weight: bold;
    }
  }
}

.trackingLocation{
  display: flex;
  flex-flow: row nowrap;
  width: 500px;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
  padding: 20px 0;

  & > div{
    width: 50%;
  }

  .separator{
    position: absolute;
    width: 25%;
    height: 2px;
    background: lightgrey;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
