@import '../imports.scss';

.titleSection{
  text-align: center;
  padding-top: 50px;
  padding-left: 15px;
  padding-right: 15px;

  h1{
    font-family: $font-heading;
  }

  p{
    font-family: $font-text;
    padding-left: 15px;
    padding-right: 15px;
    max-width: 100%;
    width: 600px;
    margin-left: auto;
    margin-right: auto;
    line-height: 1.8em;
  }
}

.notificationContainer{
  padding-top: 50px;
  min-height: 100vh;
}

.notificationList{
  width: 800px;
  max-width: 100%;
  margin: 0 auto 100px auto;
  background: #FFF;
  box-shadow: 0 0 5px #CCC;

  ul{
    margin: 0;
    padding: 0;
    width: 100%;
  }

  li{
    width: 100%;
    padding: 16px 24px;
    border-bottom: 1px solid #EEE;
    display: block;

    a{
      color: inherit;
    }
  }
}

.notificationHeader{
  display: block;
  font-weight: bold;
  font-size: 18px;
}

.notificationBody{
  display: block;
  margin: 5px 0 10px 0;
}

.notificationTime{
  color: #AAA;
  font-size: 12px;
  display: block;
}

.notificationAction{
  text-align: center;
  padding: 12px;
  cursor: pointer;
}