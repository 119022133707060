@import "../imports";

.formItem{
  margin: 15px 0;
}

.sectionTitle{
  text-align: center;
  margin-bottom: 30px;
  font-size: 21px;
  font-weight: 500;
}

.pendingReviewList{
  margin: 20px 0;
}

.pendingReview{
  .productImage{
    img{
      max-width: 200px;
    }
  }
}

.orderItem{
  position: relative;
  padding: 20px 30px;
  //cursor: pointer;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  max-width: 100%;
  width: 100%;
  margin-bottom: 20px;

  /*i{
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 30px;
    color: $color-primary;
  }

  &.active{
    background: #FAFAFA;
  }

  &:hover{
    background: #FAFAFA;
  }*/
}

.orderItemLabel{
  font-size: 16px;
  font-weight: 600;
}

.orderItemName{
  margin: 5px 0;
}

.orderItemNotes{
  margin-top: 5px;
}


.trackingHistory{
  list-style: circle;

  li{
    margin-bottom: 10px;

    &:first-child{
      font-weight: bold;
    }
  }
}

.nav{
  margin-bottom: 20px;

  button{
    background: white;
    border: 1px solid $color-black;
    border-radius: 4px;
    margin-right: 10px;

    &.active{
      background: $color-black;
      color: white;
    }
  }
}

:global{
  .full-stars-example-two {
    position: relative;

    /* use display:inline-flex to prevent whitespace issues. alternatively, you can put all the children of .rating-group on a single line */
    .rating-group {
      display: inline-flex;
    }

    /* make hover effect work properly in IE */
    .rating__icon {
      pointer-events: none;
    }

    /* hide radio inputs */
    .rating__input {
      position: absolute !important;
      left: -9999px !important;
    }

    /* hide 'none' input from screenreaders */
    .rating__input--none {
      display: none
    }

    /* set icon padding and size */
    .rating__label {
      cursor: pointer;
      padding: 0 0.1em;
      font-size: 2rem;
    }

    /* set default star color */
    .rating__icon--star {
      color: orange;
    }

    /* if any input is checked, make its following siblings grey */
    .rating__input:checked ~ .rating__label .rating__icon--star {
      color: #ddd;
    }

    /* make all stars orange on rating group hover */
    .rating-group:hover .rating__label .rating__icon--star {
      color: orange;
    }

    /* make hovered input's following siblings grey on hover */
    .rating__input:hover ~ .rating__label .rating__icon--star {
      color: #ddd;
    }
  }
}