@import "../imports.scss";

.promoBar {
  width: 100%;
  text-align: center;
  background: $color-black;
  color: $color-white;
  height: 36px;
  line-height: 36px;
  overflow: hidden;
}

.navBar {
  position: relative;
  width: 100%;
  padding: 20px 30px 10px 30px;
  display: flex;
  flex-flow: row nowrap;

  @include mobile(){
    padding: 20px 15px 20px 15px;
  }
}

.navBarMenuMobile{
  display: none;
  width: 100%;
  margin-top: 5px;

  @include mobile(){
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  & > div{
    width: auto;
    margin-right: 15px;

    a{
      color: inherit;
    }

    i{
      font-size: 21px;
      margin-top: 1px;
      color: $color-black;
    }

    img {
      max-width: 18px;
      max-height: 18px;
    }
  }
}

.navBarMenu {
  width: 100%;
  margin-top: 18px;

  @include mobile(){
    display: none;
  }

  & > ul {
    display: flex;

    & > li {
      padding-right: 25px;
      padding-bottom: 20px;

      /*&:hover{
        border-bottom: 3px solid black;
      }*/

      &.dropdown:hover .dropdownContent {
        display: block;
      }

      &.saleDropdown:hover .saleDropdownContent {
        display: block;
      }

      &.dropdown, &.saleDropdown{
        & > a{
          position: relative;

          &:hover:before{
            display: block;
          }
        }

        & > a:before{
          display: none;
          content: '';
          position: absolute;
          left: -5px;
          bottom: -23px;
          height: 2px;
          width: calc(100% + 10px);
          background: $color-black;
          z-index: 2;
        }
      }

      &.dropdown:hover, &.saleDropdown:hover{
        & > a{
          position: relative;

          &:before{
            display: block;
          }
        }
      }

      a {
        font-family: $font-heading;
        font-weight: bold;
        color: $color-black;
        transition: all .1s linear;

        &.highlighted {
          color: $color-primary;
        }

        &:hover {
          color: $color-primary;
        }
      }
    }
  }

  .dropdownContent {
    display: none;
    position: absolute;
    background-color: $color-white;
    width: 100%;
    top: 80px;
    left: 0;
    z-index: 11;

    .separator {
      width: calc(100% - 60px);
      margin-left: auto;
      margin-right: auto;
      color: white;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
    }

    .row {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      padding: 30px 30px;
    }

    .column {
      position: relative;
      width: calc(100% / 6);
      background-color: $color-white;
      height: auto;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 20px;
        width: 1px;
        height: 100%;
        background: rgba(0, 0, 0, 0.1);
      }

      &:last-child:after {
        display: none;
      }

      & > a {
        display: block;
        font-family: $font-heading;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 16px;
        margin-bottom: 15px;
      }

      ul {
        list-style-type: none;

        li {
          display: block;
          margin-bottom: 10px;

          a {
            display: block;
            font-family: $font-heading;
            font-weight: normal;
          }
        }
      }
    }
  }

  .saleDropdown{
    //position: relative;
  }

  .saleDropdownContent {
    display: none;
    position: absolute;
    background-color: $color-white;
    width: 100%;
    top: 80px;
    //left: -100px;
    left: 0;
    z-index: 1;

    .separator {
      width: calc(100% - 60px);
      margin-left: auto;
      margin-right: auto;
      color: white;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
    }

    .row {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      padding: 30px 30px 30px 300px;
    }

    .column {
      position: relative;
      width: calc(100% / 6);
      background-color: $color-white;
      height: auto;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 20px;
        width: 1px;
        height: 100%;
        background: rgba(0, 0, 0, 0.1);
      }

      &:last-child:after {
        display: none;
      }

      & > a {
        display: block;
        font-family: $font-heading;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 16px;
        margin-bottom: 15px;
      }

      ul {
        list-style-type: none;

        li {
          display: block;
          margin-bottom: 10px;

          a {
            display: block;
            font-family: $font-text;
            font-weight: bold;
          }
        }
      }
    }
  }
}

.navBarLogo {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);

  img {
    height: 50px;

    @include mobile(){
      height: 30px;
    }
  }
}

.navBarRight {
  width: auto;
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  justify-content: flex-end;
  margin-top: 18px;

  @include mobile(){
    margin-top: 5px;
  }

  .search{
    @include mobile(){
      display: none;
    }
  }

  & > div {
    width: auto;
    margin-left: 18px;

    img {
      max-width: 18px;
      max-height: 18px;
    }

    i {
      font-size: 18px;
      color: $color-black;
    }
  }

  .notification{
    position: relative;
    z-index: 11;

    & > div {
      position: relative;
    }

    & > div > img{
      cursor: pointer;
    }

    .notificationDot{
      &:after{
        position: absolute;
        display: inline-block;
        z-index: 2;
        top: 2px;
        right: 0;
        content: '';
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: crimson;
      }
    }

    .notificationList{
      width: 400px;
      position: absolute;
      top: 40px;
      right: -20px;
      background: #FFF;
      box-shadow: 0 0 10px #CCC;
      border-radius: 8px;

      ul{
        margin: 0;
        padding: 0;
        width: 100%;
      }

      li{
        width: 100%;
        padding: 16px 24px;
        border-bottom: 1px solid #EEE;

        a{
          color: inherit;
        }
      }
    }

    .notificationUnread{
      background-color: lightcyan;
    }

    .notificationHeader{
      display: block;
      font-weight: bold;
    }

    .notificationBody{
      display: block;
      margin: 5px 0 8px 0;
    }

    .notificationTime{
      display: block;
      color: #AAA;
      font-size: 12px;
      text-align: left;
    }

    .notificationAction{
      text-align: center;
      padding: 12px;
      cursor: pointer;
    }
  }
}

.headerSection {
  //top: 0;
  width: 100%;
  background: white;

  box-shadow: 0 3px 3px -3px #CCC;
  height: auto;
  z-index: 10;
}

.headerLogo {
  display: inline-block;
  margin-right: 35px;
  line-height: 60px;

  img {
    //max-height: 20px;
    max-height: 30px;
    margin-top: -18px;

    @include mobile() {
      margin-top: -12px;
    }
  }
}

.headerMenuToggle {
  position: absolute;
  right: 18px;
  top: 18px;
  display: none;

  @include mobile() {
    display: block;
  }

  .mobileSearch {
    display: inline-block;
    position: relative;
    margin-right: 15px;
    vertical-align: text-bottom;

    img {
      display: inline-block;

      @include mobile() {
        display: inline-block;
      }
    }
  }

  .mobileCart {
    display: inline-block;
    position: relative;
    margin-right: 20px;
    vertical-align: text-bottom;

    img {
      display: inline-block;

      @include mobile() {
        display: inline-block;
      }
    }

    .cartIcon {
      font-size: 24px;
    }

    :global(.cart-qty-cls) {
      top: 10px;
      padding-top: 3px;
    }
  }

  i {
    color: #1A1A1C;
    font-size: 24px;
  }
}

.headerMenu {
  display: inline-block;

  @include mobile() {
    display: none;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: inline-block;
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }

      a {
        font-family: "Open Sans", sans-serif;
        font-size: 14px;
        color: $color-black;
        font-weight: bold;
        line-height: 60px;
        transition: all .1s linear;

        &:hover {
          color: $color-primary;
        }
      }
    }
  }
}

.headerRight {
  float: right;
  line-height: 60px;

  @include mobile() {
    display: none;
  }

  ul {
    li {
      margin-left: 30px;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      line-height: 60px;

      a {
        line-height: 60px;
        font-family: "Open Sans", sans-serif;
        font-weight: bold;
        color: $color-black;
        transition: all .1s linear;

        &:hover {
          color: $color-primary;
        }
      }
    }
  }

  .wishlistBtn {
    i {
      font-size: 25px;
      color: $color-black;
      transform: translate(0, 20%);
    }
  }
}

.mobileMenu {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  overflow: auto;
  top: 0;
  left: 0;
  z-index: 2000;
  background: white;
  transition: all .1s linear;

  @include mobile() {
    display: block;
  }

  .closeBtn {
    position: absolute;
    left: 15px;
    top: 65px;
    font-size: 25px;
    height: 17px;

    &:before{
      content: '';
      position: absolute;
      left: 0;
      top: 8px;
      width: 17px;
      height: 2px;
      background: $color-black;
      transform: rotate(45deg);
    }

    &:after{
      content: '';
      position: absolute;
      left: 0;
      bottom: 7px;
      width: 17px;
      height: 2px;
      background: $color-black;
      transform: rotate(-45deg);
    }
  }

  .mobileHeaderLogo {
    //display: block;
    text-align: center;
    margin-top: 1px;
    //padding-left: 20px;

    //text-align: left;
    //
    //img{
    //  max-width: 80%;
    //}

    display: inline-block;
    //margin-right: 35px;
    line-height: 60px;
    //border-bottom: 1px solid rgba(0,0,0,0.1);
    width: 100%;

    img {
      //max-height: 20px;
      max-height: 30px;
      margin-top: -18px;

      @include mobile() {
        margin-top: 5px;
      }
    }
  }

  .mobileHeaderMenu {
    margin-top: 20px;
    padding: 0 20px;
    width: 100%;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      width: 100%;

      li {
        display: block;
        text-align: left;
        line-height: 40px;

        a {
          font-size: 24px;
          font-family: "Open Sans", sans-serif;
          color: #1A1A1C;
          transition: all .2s linear;

          &:hover {
            //font-size: 35px;
            font-weight: bold;
            color: $color-primary;
          }
        }
      }
    }
  }
}

.mobileMenuList{
  .column{
    margin-bottom: 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(0,0,0,0.1);

    &:first-child{
      border-top: 1px solid rgba(0,0,0,0.1);
      padding-top: 15px;
    }

    & > a{
      display: block;
      margin-bottom: 10px;
      color: $color-black;
      font-size: 18px;
      font-weight: bold;
      font-family: $font-heading;
      position: relative;
    }

    &.dropdown > a{
      &:before{
        content: '';
        position: absolute;
        right: 15px;
        top: 10px;
        width: 8px;
        height: 2px;
        background: $color-black;
        transform: rotate(45deg);
        transition: all .3s linear;
      }

      &:after{
        content: '';
        position: absolute;
        right: 10px;
        bottom: 15px;
        width: 8px;
        height: 2px;
        background: $color-black;
        transform: rotate(-45deg);
        transition: all .3s linear;
      }

      &.active{
        &:before{
          content: '';
          position: absolute;
          right: 15px;
          top: 10px;
          width: 8px;
          height: 2px;
          background: $color-black;
          transform: rotate(-45deg);
        }

        &:after{
          content: '';
          position: absolute;
          right: 10px;
          bottom: 15px;
          width: 8px;
          height: 2px;
          background: $color-black;
          transform: rotate(45deg);
        }
      }
    }

    ul{
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      max-height: 0;
      transition: all .5s linear;
      overflow: hidden;

      &.active{
        max-height: 100vh;
      }

      li{
        width: 50%;
        line-height: 30px;

        a{
          font-size: 14px;
          font-weight: normal;
        }
      }
    }
  }
}

.searchForm{
  position: relative;
  width: 100%;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.offCanvasOverlay{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.4);
  z-index: -1;
  display: none;
  opacity: 0;
  transition: 0.3s;

  &.active{
    display: initial;
    opacity: 1;
    right: 0;
    z-index: 15;
  }
}

.offCanvas{
  height: 100%;
  width: 500px;
  max-width: 100%;
  position: fixed;
  top: 0;
  right: -100%;
  z-index: 16;
  background: $color-white;
  transition: 0.3s;
  opacity: 0;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  overflow: auto;

  &.active{
    opacity: 1;
    right: 0;
  }

  .btnRemove{
    text-align: right;
    padding: 15px;
    margin-bottom: 30px;

    i{
      font-size: 24px;
      cursor: pointer;
    }
  }

  .cartItemsEmpty{
    text-align: center;
    line-height: 2em;
    margin-top: 5vh;

    a{
      display: inline-block;
      margin-top: 10px;
    }
  }
}

.cartItems{
  height: calc(100vh - 225px);
  overflow: auto;

  //@include mobile(){
  //  height: 60vh;
  //  max-height: 60vh;
  //}
}

.cartItem{
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  margin-bottom: 30px;
  padding: 0 30px;

  .cartItemImage{
    flex: 0 0 100px;
    margin-right: 20px;

    img{
      max-width: 100%;
      width: 100px;
      height: 100px;
      object-fit: cover;
      object-position: top;
    }
  }

  .cartItemInfo{
    width: 100%;

    .itemName{
      font-weight: bold;
      font-size: 16px;
      text-transform: uppercase;
    }
  }
}

.cartSubtotal{
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  margin: 30px 0 15px 0;
  position: absolute;
  bottom: 70px;
  width: 100%;
}

.cartActions{
  padding: 0 30px;
  position: absolute;
  bottom: 30px;
  width: 100%;
}

.marquee {
  height: 36px;
  overflow: hidden;
  position: relative;
  background: transparent;
  color: #FFFFFF;
  border: 0;
}

.marquee p {
  position: absolute;
  width: 100%;
  height: 100%;
  background: $color-black;
  color: $color-white;
  margin: 0;
  line-height: 36px;
  text-align: center;
  //transform: translateX(100%);
  //animation: scroll-left 20s linear infinite;
}

@keyframes scroll-left {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}