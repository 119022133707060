@import '../imports.scss';

%bv_hidden {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
%filled_obj {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.sliderSection{
  padding: 0 30px 80px 30px;

  @include mobile(){
    display: none;
  }

  img{
    max-width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.sliderSectionMobile{
  padding: 0 30px 80px 30px;
  display: none;

  @include mobile(){
    display: block;
    padding: 0 15px 30px 15px;
  }

  img{
    max-width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

iframe {
  width: 100%;
  height: 1080px;

  video{
    width: 100%;
    height: auto;
  }
  //position: relative;
  //pointer-events: none;
}

/*.slider{
  position: relative;
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  margin-bottom: 0;
  opacity: 0;
  visibility: hidden;
  transition:all 1.2s ease;

  &:global(.slick-initialized) {
    opacity: 1;
    visibility: visible;
  }

  :global(.slick-slide) {
    position: relative;
    height: 100vh;

    :global(.youtube){
      @include mobile(){
        display: none;
      }
    }

    video {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      transform:translate(-50%, -50%);
    }

    iframe {
      width: 100%;
      height: 100vh;
      position: relative;
      pointer-events: none;
    }

    figure {
      position: relative;
      height: 100%;
    }

    img{
      position: relative;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top center;
    }

    :global(.slide-image) {
      opacity: 0;
      height: 100%;
      background-size: cover;
      background-position: center;
      // background-color:rgba(#c46897,.38);
      // background-blend-mode:overlay;
      transition:all .8s ease;
      &.show {
        opacity: 1;
      }
    }
    :global(.image-entity) {
      width: 100%;
      opacity: 0;
      visibility: hidden;
    }
    :global(.loading) {
      position: absolute;
      top: 44%;
      left: 0;
      width: 100%;
    }
    :global(.slide-media) {
      animation:slideOut .4s cubic-bezier(0.4, 0.29, 0.01, 1);
    }
    &:global(.slick-active) {
      z-index: 1;
      :global(.slide-media) {
        animation:slideIn 2.4s cubic-bezier(0.4, 0.29, 0.01, 1);
      }
      :global(.caption) {
        opacity: 1;
        transform:translateY(0);
        transition:all .7s cubic-bezier(0.32, 0.34, 0, 1.62) .6s;
      }
    }
  }

  img{
    max-width: 100%;
  }
}*/

.hero{
  //position: sticky;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  height: auto;

  /*@include mobile(){
    position: relative;
  }*/
}

.fsHeroItem {
  position: relative;
}

.fsHeroVideoWrapper {
  position: absolute;
  height: 100%;
  width: 100%;

  img{
    display: none;
    max-width: 100%;
    height: 100vh;
    object-fit: cover;

    @include mobile(){
      display: block;
    }
  }
}

.fsHeroVideo {
  height: 100vh;
  min-width: 100%;
  width: auto;
  overflow-y: hidden;
  z-index: -999;

  @include mobile(){
    display: none;
  }
}

.fsHeroContentWrapper {
  position: relative;
  //top: 120px;
  z-index: 5;

  @include mobile(){
    text-align: left;
  }

  header{
    background: transparent;
    padding-top: 15px;

    .headerLogo{
      display: inline-block;
      text-align: left;
      margin-top: 30px;
      margin-left: 50px;

      @include mobile(){
        text-align: left;
        margin-top: 10px;
        margin-left: 30px;
      }

      img{
        max-width: 200px;

        @include mobile(){
          max-width: 100px;
        }
      }
    }

    .headerMenuToggle{
      position: absolute;
      right: 50px;
      top: 50px;
      display: none;

      @include mobile(){
        display: block;
      }

      i{
        color: #ff4c3b;
        font-size: 24px;
        cursor: pointer;
      }
    }

    .headerMenu{
      position: absolute;
      left: 50px;
      top: 230px;
      width: 200px;

      @include mobile(){
        display: none;
      }

      ul{
        list-style: none;
        margin: 0;
        padding: 0;
        width: 100%;

        li{
          display: block;
          text-align: center;

          a{
            font-size: 30px;
            font-family: "Open Sans", sans-serif;
            color: white;
            transition: all .2s linear;
            text-shadow: 1px 1px 3px rgba(0,0,0,0.3);

            &:hover{
              //font-size: 35px;
              font-weight: bold;
              color: $color-primary;
            }
          }
        }
      }
    }

    .headerSearch{
      position: absolute;
      right: 50px;
      top: 50px;

      @include mobile(){
        display: none;
      }

      i{
        margin-right: 5px;
        color: white;
        font-size: 18px;
      }

      input{
        width: 250px;
        max-width: 100%;
        background: transparent;
        border: 0;
        outline: none;
        border-bottom: 2px solid white;
        color: white;
        font-weight: bold;
        padding-bottom: 5px;

        &::placeholder {
          color: white;
          opacity: 1;
        }
      }
    }
  }
}

.mobileMenu{
  display:none;
  position:fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2000;
  background: white;
  transition: all .1s linear;

  @include mobile(){
    display: block;
  }

  .closeBtn{
    position: absolute;
    right: 25px;
    top: 15px;
    font-size: 25px;
  }

  .headerLogo{
    display: block;
    text-align: center;
    margin-top: 30px;
    //padding-left: 20px;

    img{
      max-width: 100px;
    }
  }

  .headerMenu{
    margin-top: 30px;
    padding: 0 20px;
    width: 100%;

    ul{
      list-style: none;
      margin: 0;
      padding: 0;
      width: 100%;

      li{
        display: block;
        text-align: center;
        line-height: 40px;

        a{
          font-size: 24px;
          font-family: "Open Sans", sans-serif;
          color: #1A1A1C;
          transition: all .2s linear;

          &:hover{
            //font-size: 35px;
            font-weight: bold;
            color: $color-primary;
          }
        }
      }
    }
  }
}

.fsHeroContent{
  display: none;
  width: 100%;
  text-align: center;
  margin-top: 25vh;
}

.fsHeroContentTitle{
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  font-size: 80px;
  color: white;
  margin-bottom: 30px;
  text-shadow: 2px 2px 5px rgba(0,0,0,0.1);

  @include mobile(){
    font-size: 40px;
  }
}

.fsHeroContentActionBtn{
  border: 2px solid white;
  padding: 10px 20px;
  color: white;
  background: transparent;
  text-shadow: 2px 2px 5px rgba(0,0,0,0.1);
  display: inline-block;

  @include text-shadow;

  &:hover{
    //background: white;
    //color: $color-primary;
    background: $color-primary;
    border-color: $color-primary;
    text-shadow: none;
    color: white;
  }
}

.row1{
  display: flex;
  flex-flow: row wrap;
  background: white;
  padding: 0 30px;
  //box-shadow: 0 -5px 5px -5px #CCC;

  @include mobile(){
    box-shadow: none;
    padding: 0 15px;
  }

  .col1{
    width: 50%;
    height: 0;
    padding-top: 50%;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;

    @include mobile(){
      width: 100%;
      padding-top: 100%;
    }

    img{
      width: 100%;
      object-fit: cover;
      object-position: top;
      vertical-align: top;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .col2{
    width: 50%;
    color: $color-black;
    padding: 60px;

    @include mobile(){
      width: 100%;
      padding: 30px 15px 70px 15px;
      text-align: center;
    }

    .thinTitle{
      font-family: "Open Sans", sans-serif;
      font-size: 90px;
      line-height: 95px;
      display: none;

      @include mobile(){
        font-size: 30px;
        line-height: 35px;
      }
    }

    .thickTitle{
      font-family: "Open Sans", sans-serif;
      font-size: 65px;
      line-height: 95px;
      font-weight: bold;
      word-break: break-all;
      margin-top: 80px;

      @include mobile(){
        font-size: 30px;
        line-height: 35px;
        margin-top: 20px;
      }
    }

    .subtitle{
      font-size: 16px;
      width: 100%;
      max-width: 500px;
      margin: 20px 0 60px 0;

      @include mobile(){
        margin: 20px auto 40px;
      }
    }

    .actionBtn{
      padding: 15px 30px;
      color: $color-white;
      background: $color-black;
      font-size: 18px;
      text-transform: uppercase;

      &:hover{
        background: transparentize($color-black, 0.1);
      }
    }
  }
}

.row2{
  display: flex;
  flex-flow: row wrap;
  background: white;
  padding: 0 30px;

  @include mobile(){
    padding: 0 15px;
  }

  .col2{
    width: 50%;
    //margin-top: -370px;
    height: 0;
    padding-top: 50%;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;


    @include mobile(){
      width: 100%;
      margin-top: 0;
      padding-top: 100%;
      order: -1;
    }

    img{
      width: 100%;
      object-fit: cover;
      object-position: top;
      vertical-align: top;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .col1{
    width: 50%;
    color: $color-black;
    padding: 60px;
    text-align: right;

    @include mobile(){
      width: 100%;
      padding: 30px 15px 70px 15px;
      text-align: center;
    }

    .thinTitle{
      font-family: "Open Sans", sans-serif;
      font-size: 90px;
      line-height: 95px;
      display: none;

      @include mobile(){
        font-size: 30px;
        line-height: 35px;
      }
    }

    .thickTitle{
      font-family: "Open Sans", sans-serif;
      font-size: 65px;
      line-height: 95px;
      font-weight: bold;
      word-break: break-all;
      margin-top: 80px;

      @include mobile(){
        font-size: 30px;
        line-height: 35px;
        margin-top: 20px;
      }
    }

    .subtitle{
      font-size: 16px;
      width: 100%;
      max-width: 500px;
      margin: 20px 0 60px auto;

      @include mobile(){
        margin: 20px auto 40px;
      }
    }

    .actionBtn{
      padding: 15px 30px;
      color: $color-white;
      background: $color-black;
      font-size: 18px;
      text-transform: uppercase;

      &:hover{
        background: transparentize($color-black, 0.1);
      }
    }
  }
}

.hashtagSection{
  padding: 80px 0;
  text-align: center;
  background: white;

  @include mobile(){
    //border-top: 1px solid rgba(0,0,0,0.1);
    padding: 50px 15px;
    background: #FAFAFA;
  }

  h2{
    font-family: "Open Sans", sans-serif;
    text-transform: none;
    font-size: 50px;
    margin-bottom: 20px;

    @include mobile(){
      margin-bottom: 20px;
      font-size: 30px;
    }

    a{
      color: $color-black;
    }
  }

  p{
    line-height: 1.75em;
    margin-bottom: 50px;
    font-size: 16px;
    max-width: 100%;
    width: 800px;
    margin-left: auto;
    margin-right: auto;
  }
}

.hashtagPhotos{
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 0 30px;

  @include mobile(){
    padding: 0;
  }
}

.hashtagPhoto{
  position: relative;
  width: 32%;
  height: calc(100% - 68%);
  overflow: hidden;
  box-sizing: border-box;

  @include mobile(){
    width: 100%;
    max-width: 100%;
    margin-bottom: 15px;
  }

  img{
    width: 100%;
    object-fit: cover;
    object-position: top;
    //vertical-align: top;
    //position: absolute;
    //top: 0;
    //left: 0;
  }

  .hashtagPhotoOverlay{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: all .1s linear;
    background-color: rgba(0,0,0,0.4);
  }

  &:hover .hashtagPhotoOverlay{
    opacity: 1;
  }

  .hashtagPhotoText{
    color: white;
    font-size: 16px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
}

.serviceSection{
  border: 0;

  @include mobile(){
    padding-top: 50px;
    border: 0;
  }
}

.desktopAppstoreWrapper{
  text-align: right;

  @include mobile(){
    display: none;
  }
}

.appstoreImg{
  width: 160px;
  max-width: 80%;
  margin-top: 15px;

  @include mobile(){
    width: auto;
    height: 41px;
  }
}

.desktopPlaystoreWrapper{
  @include mobile(){
    display: none;
  }
}

.playstoreImg{
  width: 200px;
  max-width: 80%;
  margin-top: 15px;

  @include mobile(){
    width: auto;
    height: 60px;
  }
}

.mobileStoreWrapper{
  text-align: center;
  display: none;

  @include mobile(){
    display: block;
    margin-bottom: 30px;
  }
}

.newsletterWrapper{
  margin-top: 50px;

  @include mobile(){
    margin-bottom: 60px;
  }

  button{
    @include mobile(){
      display: block;
      width: 150px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.sectionTitle{
  font-family: $font-heading;
  font-size: 24px;
  font-weight: bold;
  text-align: center;

  @include mobile(){
    margin-bottom: 5px;
  }
}

.newsletterInput{
  border: 0;
  outline: none;
  background: transparent;
  text-align: center;
  display: block;
  width: 500px;
  max-width: 80%;
  border-bottom: 2px solid rgba(0,0,0,0.5);
  margin: 50px auto 30px auto;
  padding-bottom: 5px;
  font-size: 18px;
}

.newsletterForm{
  text-align: center;
}

.storeWrapper{
  margin-top: 80px;
  margin-bottom: 50px;
}

.storeIcon{
  margin-top: 0;
  text-align: center;
}