@import "../node_modules/antd/dist/antd.css";

@import "./assets/scss/font-awesome";
@import "./assets/scss/bootstrap";
@import "./assets/scss/slick.scss";
@import "./assets/scss/slick-theme.scss";
@import "./assets/scss/animate.scss";
@import "./assets/scss/themify.scss";

//@import "./assets/scss/color1.scss";
@import './assets/scss/theme/variables';
$theme-deafult: #e73b27; /*#ff4c3b;*/
@import './assets/scss/theme/style';
@import './assets/scss/theme/menu';
@import './assets/scss/theme/responsive';

.btn-link{
  background: none;
  border: none;
  padding: 0;
  color: $theme-deafult;
  cursor: pointer;

  &:hover{
    cursor: pointer;
    text-decoration: none;
  }
}





