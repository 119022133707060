@import '../imports.scss';

.coverSection{
  padding-top: 0;
  text-align: center;

  img{
    max-width: 100%;
  }
}

.titleSection{
  text-align: center;
  padding-top: 50px;
  padding-left: 15px;
  padding-right: 15px;
  color: $color-black;

  h1{
    font-family: $font-heading;
  }
}

.stepSection{
  padding: 30px;
  width: 1000px;
  max-width: 100%;
  margin: 50px auto;
  color: $color-black;
}

.stepWord{
  width: 150px;
  text-align: center;
  font-weight: bold;
  font-size: 30px;
  font-family: $font-heading;
  margin-bottom: 30px;

  @include mobile(){
    width: 100px;
    font-size: 25px;
    margin-bottom: 15px;
  }
}

.stepItems{
  display: flex;
  flex-flow: column nowrap;
}

.stepItem{
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 30px;

  img{
    max-width: 100%;
    display: inline-block;
    margin: 20px 0;
  }

  .number{
    font-family: $font-heading;
    font-weight: bold;
    font-size: 100px;
    line-height: 1em;
    text-align: center;
    width: 150px;

    @include mobile(){
      width: 100px;
      font-size: 75px;
    }
  }

  .info{
    flex: 1;
    padding-top: 5px;

    .title{
      font-family: $font-heading;
      font-weight: bold;
      font-size: 24px;
    }

    .desc{

    }
  }
}