@import '../imports.scss';

.hero{
  position: relative;
  top: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100vh;
}

.fsHeroItem {
  position: absolute;
  height: 100%;
  width: 100%;
}

.fsHeroVideoWrapper {
  position: absolute;
  height: 100%;
  width: 100%;

  img{
    object-fit: cover;
    object-position: top;
    height: 100%;
    width: 100%;
  }
}

.fsHeroVideo {
  height: 100vh;
  min-width: 100%;
  width: auto;
  overflow-y: hidden;
  z-index: -999;
}

.fsHeroContentWrapper {
  position: relative;
  //top: 120px;
  z-index: 5;

  @include mobile(){
    text-align: left;
  }

  header{
    background: transparent;
    padding-top: 15px;

    .headerLogo{
      display: inline-block;
      text-align: left;
      margin-top: 30px;
      margin-left: 50px;

      @include mobile(){
        text-align: left;
        margin-top: 10px;
        margin-left: 30px;
      }

      img{
        max-width: 200px;

        @include mobile(){
          max-width: 100px;
        }
      }
    }

    .headerMenuToggle{
      position: absolute;
      right: 50px;
      top: 50px;
      display: none;

      @include mobile(){
        display: block;
      }

      i{
        color: #ff4c3b;
        font-size: 24px;
        cursor: pointer;
      }
    }

    .headerMenu{
      position: absolute;
      left: 50px;
      top: 230px;
      width: 200px;

      @include mobile(){
        display: none;
      }

      ul{
        list-style: none;
        margin: 0;
        padding: 0;

        li{
          display: block;
          text-align: center;

          a{
            font-size: 30px;
            font-family: "Open Sans", sans-serif;
            color: white;
            transition: all .2s linear;
            text-shadow: 1px 1px 3px rgba(0,0,0,0.3);

            &:hover{
              //font-size: 35px;
              font-weight: bold;
              color: $color-primary;
            }
          }
        }
      }
    }

    .headerSearch{
      position: absolute;
      right: 50px;
      top: 30px;

      @include mobile(){
        display: none;
      }

      i{
        margin-right: 5px;
        color: white;
        font-size: 18px;
      }

      input{
        width: 250px;
        max-width: 100%;
        background: transparent;
        border: 0;
        outline: none;
        border-bottom: 2px solid white;
        color: white;
        font-weight: bold;
        padding-bottom: 5px;

        &::placeholder {
          color: white;
          opacity: 1;
        }
      }
    }
  }
}

.mobileMenu{
  display:none;
  position:fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2000;
  background: white;
  transition: all .1s linear;

  @include mobile(){
    display: block;
  }

  .closeBtn{
    position: absolute;
    right: 25px;
    top: 15px;
    font-size: 25px;
  }

  .headerLogo{
    display: block;
    text-align: center;
    margin-top: 30px;
    //padding-left: 20px;

    img{
      max-width: 100px;
    }
  }

  .headerMenu{
    margin-top: 30px;
    padding: 0 20px;
    width: 100%;

    ul{
      list-style: none;
      margin: 0;
      padding: 0;
      width: 100%;

      li{
        display: block;
        text-align: center;
        line-height: 40px;

        a{
          font-size: 24px;
          font-family: "Open Sans", sans-serif;
          color: #1A1A1C;
          transition: all .2s linear;

          &:hover{
            //font-size: 35px;
            font-weight: bold;
            color: $color-primary;
          }
        }
      }
    }
  }
}

.fsHeroContent{
  display: block;
  width: 100%;
  text-align: center;
  margin-top: 25vh;
}

.fsHeroContentTitle{
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  font-size: 80px;
  color: white;

  @include mobile(){
    font-size: 40px;
  }
}

.fsHeroContentActionBtn{
  border: 2px solid white;
  padding: 10px 20px;
  color: white;
  background: transparent;
  margin-top: 20px;
  display: inline-block;

  @include text-shadow;

  &:hover{
    //background: white;
    //color: $color-primary;
    background: $color-primary;
    border-color: $color-primary;
    text-shadow: none;
    color: white;
  }
}

.row1{
  display: flex;
  flex-flow: row wrap;
  background: white;
  padding-top: 0;
  box-shadow: 0 -5px 5px -5px #CCC;

  @include mobile(){
    box-shadow: none;
  }

  .col1{
    width: 50%;

    @include mobile(){
      width: 100%;
    }

    img{
      max-width: 100%;
    }
  }

  .col2{
    width: 50%;
    color: $color-black;
    padding: 60px;

    @include mobile(){
      width: 100%;
      padding: 50px 40px 70px 40px;
      text-align: center;
    }

    .thinTitle{
      font-family: "Open Sans", sans-serif;
      font-size: 90px;
      line-height: 95px;

      @include mobile(){
        font-size: 30px;
        line-height: 35px;
      }
    }

    .thickTitle{
      font-family: "Open Sans", sans-serif;
      font-size: 90px;
      line-height: 95px;
      font-weight: bold;

      @include mobile(){
        font-size: 30px;
        line-height: 35px;
      }
    }

    .subtitle{
      font-size: 16px;
      margin-top: 40px;
      margin-bottom: 50px;

      @include mobile(){
        margin-top: 20px;
        margin-bottom: 25px;
      }
    }

    .actionBtn{
      border: 1px solid $color-black;
      padding: 5px 30px;
      color: $color-black;
      background: transparent;

      &:hover{
        background: $color-black;
        color: white;
      }
    }
  }
}

.row2{
  display: flex;
  flex-flow: row wrap;
  background: white;
  padding-top: 0;
  box-shadow: 0 -5px 5px -5px #CCC;

  .col2{
    width: 50%;
    margin-top: -370px;

    @include mobile(){
      width: 100%;
      margin-top: 0;
      order: -1;
    }

    img{
      max-width: 100%;
    }
  }

  .col1{
    width: 50%;
    color: $color-black;
    padding: 60px;
    text-align: right;

    @include mobile(){
      width: 100%;
      padding: 50px 40px 70px 40px;
    }

    .thinTitle{
      font-family: "Open Sans", sans-serif;
      font-size: 90px;
      line-height: 95px;

      @include mobile(){
        font-size: 30px;
        line-height: 35px;
      }
    }

    .thickTitle{
      font-family: "Open Sans", sans-serif;
      font-size: 90px;
      line-height: 95px;
      font-weight: bold;

      @include mobile(){
        font-size: 30px;
        line-height: 35px;
      }
    }

    .subtitle{
      font-size: 16px;
      margin-top: 40px;
      margin-bottom: 50px;

      @include mobile(){
        margin-top: 20px;
        margin-bottom: 25px;
      }
    }

    .actionBtn{
      border: 1px solid $color-black;
      padding: 5px 30px;
      color: $color-black;
      background: transparent;

      &:hover{
        background: $color-black;
        color: white;
      }
    }
  }
}

.hashtagSection{
  padding: 80px 0;
  text-align: center;
  background: white;

  @include mobile(){
    //border-top: 1px solid rgba(0,0,0,0.1);
    padding: 50px 30px;
    background: #FAFAFA;
  }

  h2{
    font-family: "Open Sans", sans-serif;
    text-transform: none;

    @include mobile(){
      margin-bottom: 20px;
      font-size: 30px;
    }
  }

  p{
    line-height: 1.4em;
    margin-bottom: 30px;
  }
}

.hashtagPhotos{
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.hashtagPhoto{
  position: relative;
  margin: 20px;
  max-width: 30%;
  width: 300px;
  height: 300px;

  @include mobile(){
    max-width: 100%;
  }

  img{
    max-width: 100%;
    width: 300px;
    height: 300px;
    object-fit: cover;
  }

  .hashtagPhotoOverlay{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: all .1s linear;
    background-color: rgba(0,0,0,0.4);
  }

  &:hover .hashtagPhotoOverlay{
    opacity: 1;
  }

  .hashtagPhotoText{
    color: white;
    font-size: 16px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
}

.serviceSection{
  @include mobile(){
    border: 0;
  }
}